<template>
  <b-modal
    :id="id"
    :ref="id"
    hide-header
    hide-footer
    centered
  >
    <div class="text-center p-2">
      <feather-icon
        size="64"
        icon="AlertCircleIcon"
        class="text-warning"
      />
      <h2
        v-if="title"
        class="my-2"
      >
        {{ title }}
      </h2>
      <h6
        v-if="subtitle"
        class="my-2"
      >
        {{ subtitle }}
      </h6>
      <div class="d-flex justify-content-center">
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click.prevent="hideModal"
        >
          ยกเลิก
        </b-button>
        <b-button
          :variant="confirmVariant"
          class="mr-1"
          @click.prevent="onClickConfirm"
        >
          {{ confirmText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'ยืนยัน',
    },
    confirmVariant: {
      type: String,
      default: 'success',
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (bvEvent.type === 'hide' && modalId === this.id) {
        this.$emit('cancel')
      }
    })
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
    },
    onClickConfirm() {
      this.$emit('confirm')
      this.$bvModal.hide(this.id)
    },
  },
}
</script>
